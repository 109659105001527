import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-cancel-purchase",
  templateUrl: "./cancel-purchase.component.html",
  styleUrls: ["./cancel-purchase.component.scss"],
})
export class CancelPurchaseComponent implements OnInit {
  constructor(private toastr: ToastrService) {}

  ngOnInit(): void {
    this.toastr.info(
      "Payment canceled, let come back later when you are ready."
    );
  }
}
