<!-- <app-breadcrumb></app-breadcrumb> -->
<ng-container>
  <div class="container-fluid" style="height: 80vh">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header"></div>
          <div class="card-body text-center">
            <div class="row" *ngIf="isSuccess">
              <div class="col-lg-6 col-md-12 mb-3">
                <img
                  class="cancel-img"
                  src="https://storage.chatgptkeeper.com/purchase-thank-you-small.jpg"
                />
              </div>
              <div class="col-lg-6 col-md-12 mb-3">
                <h1 class="display-3">{{ "Thanks a Million!" | translate }}</h1>
                <p class="lead">
                  {{
                    "Thanks for your purchase! You’ve just made your browsing experience even better. Enjoy the new features! 👍"
                      | translate
                  }}
                  {{ "Below is the license key for your purchase:" | translate
                  }}<br />
                  <strong
                    ><mark>{{ license }}</mark></strong
                  ><br />
                  {{
                    "You can use this key to activate your account in the settings page within the extension."
                      | translate
                  }}
                </p>
                <hr />
                <p>
                  {{ "Having trouble?" | translate }}
                  <a href="mailto:support@chatgptkeeper.com"
                    >support@chatgptkeeper.com</a
                  >
                </p>
              </div>
            </div>
            <div class="row" *ngIf="!isSuccess">
              <div class="col mb-3">
                <h1 class="display-3">{{ "Oops!" | translate }}</h1>
                <p class="lead">
                  {{
                    "Something went wrong with your purchase. Please try again later."
                      | translate
                  }}
                </p>
                <hr />
                <p>
                  {{ "Having trouble?" | translate }}
                  <a href="mailto:support@chatgptkeeper.com"
                    >support@chatgptkeeper.com</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
