import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http"; // Import HttpHeaders from '@angular/common/http'

@Injectable({
  providedIn: "root",
})
export class CommonService {
  constructor() {}

  appendAuthenticationHeader(headers: HttpHeaders): HttpHeaders {
    const sessionToken = localStorage.getItem("session_token");
    if (sessionToken) {
      return headers.append("Authorization", "Bearer " + sessionToken);
    }
    return headers;
  }
}
