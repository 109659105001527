<!-- <app-breadcrumb></app-breadcrumb> -->
<ng-container *ngIf="!purchasedStatus">
  <div class="container-fluid" style="height: 80vh !important">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body row flex-row">
            <div class="col-xl-4 col-sm-12 xl-50 mb-3">
              <div class="card text-center alert alert-secondary">
                <div class="card-body">
                  <h5>{{ "Free" | translate }}</h5>
                  <h3>{{ "$0" | translate }}</h3>
                  <ul class="features">
                    <li>Advanced search, bookmarking.</li>
                    <li>Image filtering.</li>
                    <li>Date range search.</li>
                    <li>Export/Import data.</li>
                    <li>Speech recognition.</li>
                    <li>
                      Initial 30 searches, with 3 additional searches added
                      every 3 hours.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-sm-12 xl-50 mb-3">
              <div class="card text-center alert alert-info">
                <div class="card-body">
                  <h5>
                    {{ "Starter" | translate }}
                  </h5>
                  <h3>{{ "$3 every 3 months" | translate }}</h3>
                  <ul class="features">
                    <li>Everything in Free.</li>
                    <li>Assign messages to categories for better filtering.</li>
                    <li>Unlimited searches for 3 months.</li>
                  </ul>
                  <div class="review-to-claim">
                    Review us on
                    <a
                      href="https://chromewebstore.google.com/detail/chatgpt-keeper/ajcnpllmohcdhdbnemhdhjagphbkdelf?hl=en&authuser=0"
                      target="_blank"
                      >Chrome Store</a
                    >
                    with a rating and comment, then email us at
                    <a href="mailto:support@chatgptkeeper.com"
                      >support@chatgptkeeper.com</a
                    >
                    to claim a Starter license key for free.
                  </div>
                </div>
                <a
                  class="btn btn-lg btn-warning btn-block"
                  href="javascript:void(0)"
                  (click)="stripeCheckout('starter')"
                >
                  <h5 class="mb-0">{{ buttonLabel }}</h5>
                </a>
              </div>
            </div>

            <div class="col-xl-4 col-sm-12 xl-50 mb-3">
              <div class="card text-center alert alert-info">
                <div class="card-body">
                  <h5>
                    {{ "Explorer" | translate }}
                  </h5>
                  <h3>{{ "$10 every year" | translate }}</h3>
                  <ul class="features">
                    <li>Everything in Free.</li>
                    <li>Assign messages to categories for better filtering.</li>
                    <li>Unlimited searches for one year.</li>
                  </ul>
                </div>
                <!-- <a class="btn btn-lg btn-warning btn-block disabled" href="javascript:void(0)">
                  <h5 class="mb-0">{{ 'Free' | translate }}</h5>
                </a> -->
                <a
                  class="btn btn-lg btn-warning btn-block"
                  href="javascript:void(0)"
                  (click)="stripeCheckout('explorer')"
                >
                  <h5 class="mb-0">{{ buttonLabel }}</h5>
                </a>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="col-xl-12 text-center">
              <strong>{{ "Note" | translate }}</strong
              >:
              {{
                "After payment, you will get a license key to enter in the extension. This key will be used to activate your account."
                  | translate
              }}<br />
              {{
                "Each Starter/Explorer account allows only two devices to be logged in at a time. "
                  | translate
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
