<div class="jumbotron">
  <h1 class="display-5">Privacy Policy</h1>
  <p class="lead">
    We take your privacy very seriously.
    <mark
      >All your data, including conversations, images, files, and other
      information, remains solely on your local browser and is never transmitted
      to or stored on our servers.</mark
    >
    This Privacy Policy describes how we handle your personal information when
    you use our browser extension, ChatGPTKeeper.
  </p>
</div>

<div class="row marketing">
  <div class="col-lg-6">
    <h4>User Data Storage</h4>
    <p>
      All user data is stored locally on the user's device. We do not store any
      of your data on external servers.
    </p>

    <h4>Extension Purpose</h4>
    <p>
      ChatGPTKeeper is designed to help you save chat history to your local
      device for later filtering and search purposes. We do not collect any
      personal information or track your browsing activity.
    </p>
  </div>

  <div class="col-lg-6">
    <h4>Data Deletion</h4>
    <p>
      We understand that you may want to delete your data at any time.
      Therefore, we have included a delete button in the settings page of the
      extension for your convenience.
    </p>
    <h4>Speech Recognition & Synthesis</h4>
    <p>
      ChatGPTKeeper utilizes speech recognition and synthesis to enhance your
      experience when communicating with ChatGPT. However, we do not store any
      speech data or other personal information outside of your local machine.
    </p>
  </div>
</div>
