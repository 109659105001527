<div class="container">
  <div class="header clearfix">
    <nav>
      <ul class="nav nav-pills float-right">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/']">Home</a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            href="https://chromewebstore.google.com/detail/chatgpt-keeper/ajcnpllmohcdhdbnemhdhjagphbkdelf?hl=en&authuser=0"
            target="_blank"
            >Install</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/purchase']">Pricing</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/privacy-policy']"
            >Privacy Policy</a
          >
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" [routerLink]="['/faq']">FAQ</a>
        </li> -->
        <li class="nav-item">
          <a
            class="nav-link"
            href="https://www.reddit.com/r/ChatGPTKeeper/"
            target="_blank"
            >Forum</a
          >
        </li>
        <!-- <li class="nav-item">
          <a
            class="nav-link"
            href="https://microsoftedge.microsoft.com/addons/detail/chatgpt-keeper/gkdbmnpmliemohdbkopemmncjbpjcfgi"
            target="_blank"
            >Install on Edge</a
          >
        </li> -->
        <!-- <li class="nav-item">
          <app-languages></app-languages>
        </li> -->
      </ul>
    </nav>
    <h4 class="text-muted">
      <a href="/"
        ><img src="assets/logo.png" alt="lacodeid" width="50" height="50" />
        ChatGPT Keeper
      </a>
    </h4>
  </div>
  <router-outlet></router-outlet>
  <footer class="footer text-center">
    <p>
      <a href="mailto:support@chatgptkeeper.com">support@chatgptkeeper.com</a> |
      <a href="https://www.reddit.com/r/ChatGPTKeeper" target="_blank"
        >Reddit</a
      >
    </p>
  </footer>
</div>
