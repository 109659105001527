import { AuthService } from "src/app/services/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { APIResponse, User } from "src/app/services/constant.service";
declare var require;
const Swal = require("sweetalert2");
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-purchase",
  templateUrl: "./purchase.component.html",
  styleUrls: ["./purchase.component.scss"],
})
export class PurchaseComponent implements OnInit {
  @Output() purchaseClickEvent = new EventEmitter();
  public isExtend = false;
  public purchasedStatus: string;
  public isStripeReady: boolean = false;
  public stripeInt = 0;
  public successInt = 0;
  public ref = null;
  public isSuccess = false;
  public buttonLabel = "Purchase";
  public expiryDate = null;
  public currentUnixtime;
  public userType = "free";
  public emailAddress = "";
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public authService: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private toster: ToastrService,
    private http: HttpClient
  ) {
    try {
      this.purchasedStatus = this.route.snapshot.data["title"].includes(
        "success"
      )
        ? "success"
        : "cancel";
      if (this.purchasedStatus == "cancel") {
        this.toastr.info(
          "Payment canceled, let come back later when you are ready."
        );
        this.router.navigateByUrl("/");
      }
      console.log(this.purchasedStatus);
    } catch (e) {}
  }

  ngOnInit(): void {}

  stripeCheckout(type) {
    const apiEndpoint = environment.apiEndpoint;
    const url = `${apiEndpoint}/create-stripe-checkout`;
    let ref = {
      type: type,
      production: environment.production,
    };
    this.http
      .post(url, {
        ref: btoa(JSON.stringify(ref)),
      })
      .subscribe({
        next: (apiResponse: APIResponse) => {
          this.toster.clear();
          if (apiResponse.success) {
            const stripe = new window["Stripe"](
              environment.stripePublishableKey,
              {
                apiVersion: "2020-08-27",
              }
            );
            const sessionId = apiResponse.id;
            stripe.redirectToCheckout({ sessionId: sessionId });
          } else {
            this.toster.error(
              this.translate.instant(
                "Something went wrong: " + apiResponse.message
              ),
              "",
              {
                timeOut: 60000,
              }
            );
          }
          Swal.close();
        },
        error: (apiResponse: APIResponse) => {
          Swal.close();
          this.toster.error(apiResponse.message);
        },
      });

    if (!this.authService.userData) {
      // sessionStorage.setItem("pending_purchase_type", type);
      // this.router.navigate(["/auth/login"]);
    } else {
      Swal.showLoading();
      // this.fns.functions.useFunctionsEmulator(
      //   "http://127.0.0.1:5001/speaknplay/us-central1/createStripeCheckout"
      // );
      // publishable key
      // const stripe = new window['Stripe'](environment.stripePublishableKey, {
      //   apiVersion: '2020-08-27',
      // });
      // const createStripeCheckout = this.fns.httpsCallable('createStripeCheckout');
      // createStripeCheckout({
      //   type: type,
      //   production: environment.production,
      //   ref: btoa(this.userData.uid + '||' + type),
      //   hostname: location.hostname,
      // })
      //   .toPromise()
      //   .then((response) => {
      //     // Swal.close();
      //     const sessionId = response.id;
      //     stripe.redirectToCheckout({ sessionId: sessionId });
      //   });
    }
  }

  afterPaymentSuccessful(ref: string) {
    // verify ref
    Swal.showLoading();
    const arr = ref.split("||");
    if (arr.length != 2) {
      this.toastr.error("Invalid ref");
      return;
    }
    const uid = arr[0];
    const purchaseType = arr[1];
    this.userType =
      purchaseType.indexOf("student") > -1 ? "student" : "teacher";
    //   const userRef: AngularFirestoreDocument<any> = this.afs.doc("users/" + uid);
    //   this.successInt = setInterval(() => {
    //     userRef.get().subscribe(async (result) => {
    //       let remoteUserData = this.authService.decompressUserData(
    //         result.data().userData
    //       );
    //       // if purchase date is within 1 hour
    //       if (
    //         Math.abs(remoteUserData.purchasedDate - this.currentUnixtime) < 3600
    //       ) {
    //         this.authService.handleExistUser(this.authService.user, result);
    //         // this.authService.userData.purchasedDate =
    //         //   remoteUserData.purchasedDate;
    //         // this.authService.userData.expiredDate = remoteUserData.expiredDate;
    //         this.isSuccess = true;
    //         clearInterval(this.successInt);
    //         Swal.close();
    //       }
    //     });
    //   }, 2000);
  }
}
