import { AuthService } from "src/app/services/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { APIResponse, User } from "src/app/services/constant.service";
declare var require;
const Swal = require("sweetalert2");
import { TranslateService } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-success-purchase",
  templateUrl: "./success-purchase.component.html",
  styleUrls: ["./success-purchase.component.scss"],
})
export class SuccessPurchaseComponent implements OnInit {
  @Output() purchaseClickEvent = new EventEmitter();
  public isExtend = false;
  public purchasedStatus: string;
  public isStripeReady: boolean = false;
  public stripeInt = 0;
  public successInt = 0;
  public ref = null;
  public isSuccess = false;
  public buttonLabel = "Purchase";
  public expiryDate = null;
  public currentUnixtime;
  public userData = null;
  public license = "";
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public authService: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private http: HttpClient,
    private toster: ToastrService
  ) {}

  ngOnInit(): void {
    this.currentUnixtime = moment().format("X");
    this.route.params.subscribe((params) => {
      if (typeof params["ref"] != "undefined") {
        console.log(params["ref"]);
        this.ref = params["ref"];
        this.afterPaymentSuccessful(this.ref);
      }
    });
  }

  afterPaymentSuccessful(ref: string) {
    // verify ref
    // Swal.showLoading();
    let dataObject = JSON.parse(atob(ref));
    if (!dataObject["type"]) {
      this.toastr.error("Invalid ref");
      return;
    }
    const emailAddress = dataObject["email"];
    const purchaseType = dataObject["type"];
    this.license = dataObject["license"];
    if (!this.license) {
      this.toastr.error("Invalid license");

      return;
    }
    this.isSuccess = true;
    // this.userType =
    //   purchaseType.indexOf("student") > -1 ? "student" : "teacher";
    //   const userRef: AngularFirestoreDocument<any> = this.afs.doc("users/" + uid);
    //   this.successInt = setInterval(() => {
    //     userRef.get().subscribe(async (result) => {
    //       let remoteUserData = this.authService.decompressUserData(
    //         result.data().userData
    //       );
    //       // if purchase date is within 1 hour
    //       if (
    //         Math.abs(remoteUserData.purchasedDate - this.currentUnixtime) < 3600
    //       ) {
    //         this.authService.handleExistUser(this.authService.user, result);
    //         // this.authService.userData.purchasedDate =
    //         //   remoteUserData.purchasedDate;
    //         // this.authService.userData.expiredDate = remoteUserData.expiredDate;
    //         this.isSuccess = true;
    //         clearInterval(this.successInt);
    //         Swal.close();
    //       }
    //     });
    //   }, 2000);
    // const apiEndpoint = environment.apiEndpoint;
    // const url = `${apiEndpoint}/get-license`;
    // Swal.showLoading();
    // this.http
    //   .post(url, {
    //     email: emailAddress,
    //   })
    //   .subscribe({
    //     next: (apiResponse: APIResponse) => {
    //       this.toster.clear();
    //       if (apiResponse.success) {
    //         this.instruction =
    //           "Please use your email address " +
    //           emailAddress +
    //           " and license key " +
    //           apiResponse.user.license +
    //           " to activate your ChatGPT Keeper extension from the extension settings page.";
    //         setTimeout(() => {
    //           this.router.navigate(["/"]);
    //         }, 1000);
    //       } else {
    //         this.toster.error(
    //           this.translate.instant(
    //             "Something went wrong: " + apiResponse.message
    //           ),
    //           "",
    //           {
    //             timeOut: 60000,
    //           }
    //         );
    //       }
    //       Swal.close();
    //     },
    //     error: (apiResponse: APIResponse) => {
    //       Swal.close();
    //       this.toster.error(apiResponse.message);
    //     },
    //   });
  }
}
