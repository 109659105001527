<!-- <app-breadcrumb></app-breadcrumb> -->
<ng-container>
  <div class="container-fluid" style="height: 80vh">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header"></div>
          <div class="card-body text-center">
            <div class="row">
              <div class="col-lg-6 col-md-12 mb-3">
                <img
                  class="cancel-img"
                  src="https://storage.chatgptkeeper.com/purchase-cancel-small.jpg"
                />
              </div>
              <div class="col-lg-6 col-md-12 mb-3">
                <h1 class="display-3">{{ "Thank You!" | translate }}</h1>
                <p class="lead">
                  {{
                    "Your extension license isn't going anywhere. Feel free to come back anytime when you’re ready to make it official!"
                      | translate
                  }}
                </p>
                <hr />
                <p>
                  {{ "Having trouble?" | translate }}
                  <a href="mailto:support@chatgptkeeper.com"
                    >support@chatgptkeeper.com</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
