<div class="jumbotron">
  <h1 class="display-5">Search & bookmark your ChatGPT conversations</h1>
  <p class="lead">
    ChatGPT Keeper is a Chrome/Edge extension that indexes your past
    conversations for easy retrieval, enhancing your chat management. This
    article explores its key features.
    <br /><br />
  </p>

  <div class="embed-responsive embed-responsive-16by9">
    <iframe
      class="embed-responsive-item"
      src="https://www.youtube.com/embed/60S6epb-Ze0?si=1s-iEDFwKf3lLenr"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerpolicy="strict-origin-when-cross-origin"
      allowfullscreen
    ></iframe>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-12 col-md-6">
      <h4>Enhanced Search with Scoring</h4>
      <p>
        Equipped with a scoring system, ChatGPT Keeper's search engine
        effectively prioritizes results to ensure relevance, covering exact,
        partial, or close matches.
      </p>
      <h4>Date Range Search</h4>
      <p>
        Search for messages within specific date ranges, allowing for precise
        information retrieval.
      </p>
      <h4>Category Assignment</h4>
      <p>
        Assign messages to categories for better organization and filtering,
        streamlining your chat management.
      </p>
      <h4>Scalable Data Management</h4>
      <p>
        Designed for large data volumes, ChatGPT Keeper can manage extensive
        chat histories without space concerns.
      </p>
    </div>

    <div class="col-12 col-md-6">
      <h4>Bookmarking and Bookmark Search</h4>
      <p>
        Bookmark important messages and search within your bookmarks for quick
        retrieval of critical information.
      </p>

      <h4>Local Data Control</h4>
      <p>
        Your chat data is stored locally and can be deleted easily, ensuring
        full control over your information.
      </p>

      <h4>Data Portability</h4>
      <p>
        Easily export and re-import your chat history, allowing for sharing or
        migration to another device.
      </p>
      <h4>Voice Capabilities</h4>
      <p>
        Use voice commands to manage your chat history or have messages read out
        loud, thanks to voice recognition and synthesis features.
      </p>
    </div>
  </div>
</div>
