import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import "rxjs/add/operator/toPromise";
import { HttpHeaders } from "@angular/common/http";
import { CommonService } from "./common.service";

@Injectable({
  providedIn: "root",
})
export class R2Service {
  errorMessage: string | undefined;
  constructor(public http: HttpClient, private commonService: CommonService) {}

  async uploadFile(
    body: string,
    filename: string,
    isPublic = 0
  ): Promise<void> {
    const apiEndpoint = environment.apiEndpoint;
    const url = apiEndpoint + "/storage";
    const data = JSON.stringify({
      operation: "write",
      key: filename,
      body: body,
      isPublic: isPublic,
    });

    try {
      const response: any = await this.http
        .put(url, data, {
          headers: this.commonService.appendAuthenticationHeader(
            new HttpHeaders().set("Content-Type", "application/json")
          ),
          observe: "response",
        })
        .toPromise();

      if (response.status === 200) {
        this.errorMessage = undefined;
      } else {
        this.handleError(response.statusText);
      }
    } catch (error) {
      this.handleError(error.message);
    }
  }

  async getFile(filename: string, isPublic = 0): Promise<any> {
    const apiEndpoint = environment.apiEndpoint;
    const url = apiEndpoint + "/" + filename; // Replace with your Worker's URL

    try {
      const data = JSON.stringify({
        operation: "read",
        key: filename,
        body: "",
        isPublic: isPublic,
      });
      const response = await this.http
        .post(url, data, {
          observe: "response",
          headers: this.commonService.appendAuthenticationHeader(
            new HttpHeaders().set("Content-Type", "application/json")
          ),
        })
        .toPromise();

      if (response.status === 200) {
        return response.body;
      } else if (response.status === 404) {
        this.errorMessage = "File not found.";
        this.handleError(this.errorMessage);
      } else {
        this.handleError(response.statusText);
        this.handleError(response.statusText);
      }
    } catch (error) {
      this.handleError(error.message);
    }
  }

  async deleteFile(filename: string, isPublic = 0): Promise<void> {
    const apiEndpoint = environment.apiEndpoint;
    const url = apiEndpoint + "/" + filename; // Replace with your Worker's URL
    const data = JSON.stringify({
      operation: "delete",
      key: filename,
      body: "",
      isPublic: isPublic,
    });
    try {
      const response = await this.http
        .post(url, data, {
          observe: "response",
          headers: this.commonService.appendAuthenticationHeader(
            new HttpHeaders().set("Content-Type", "application/json")
          ),
        })
        .toPromise();

      if (response.status === 200) {
        this.errorMessage = undefined;
      } else {
        this.handleError(response.statusText);
      }
    } catch (error) {
      this.handleError(error.message);
    }
  }

  downloadFile(url: string, filename: string) {
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    link.click();
  }

  private handleError(message: string) {
    this.errorMessage = message;
  }
}

interface FileData {
  content: string; // For upload
  url?: string; // For get
}
