import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";
import { map } from "rxjs/operators";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class AdminGuard implements CanActivate {
  constructor(public authService: AuthService, public router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // Guard for user is login or not
    // let user = JSON.parse(localStorage.getItem('user'));
    const func = (): boolean => {
      // let user = this.authService.userData;
      if (!this.authService.userData) {
        this.router.navigate(["/auth/login"]);
        return true;
      } else {
        // if (this.router.url.includes('auth/register') ||
        //   this.router.url.includes('auth/login') ||
        //   this.router.url.includes('auth/reset-password') ||
        //   this.router.url.includes('privacy_policy')
        // ) {
        //     // do not thing
        // }

        if (
          !this.authService.userData.expiredDate ||
          this.authService.userData.expiredDate < parseInt(moment().format("X"))
        ) {
          if (this.authService.mobileAndTabletcheck()) {
            this.router.navigate(["media/media"]);
          } else {
            this.router.navigate(["/"]);
          }
        }
      }
      return true;
    };
    if (this.authService.receivedFirstCallbackFromFirebase) {
      return func();
    } else {
      return this.authService.observerAuthentication.pipe(
        map((e) => {
          console.log("guard received push");
          return func();
        })
      );
    }
  }
}
