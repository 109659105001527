import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { Injectable, OnInit, NgZone } from "@angular/core";
import { Router } from "@angular/router";

// import { AngularFireAuth } from '@angular/fire/auth';
// import * as firebase from 'firebase/app';
// import 'firebase/auth';
import { ToastrService, ActiveToast } from "ngx-toastr";
import { CookieService } from "ngx-cookie-service";
import { HttpClient } from "@angular/common/http";
import { Observable, of, Subject, from } from "rxjs";
import { environment } from "src/environments/environment";
import * as moment from "moment";
import { User } from "./constant.service";
import * as Sentry from "@sentry/angular";

declare var require;
const Swal = require("sweetalert2");
import { KvService } from "./kv.service";
import { R2Service } from "./r2.service";

@Injectable({
  providedIn: "root",
})
export class AuthService implements OnInit {
  public accessToken: string = "";
  public refreshToken: string = "";
  public userData: User;
  // public user: User;
  public showLoader: boolean = false;
  public isAuthenticationCallback: boolean = false;
  public receivedFirstCallbackFromFirebase = false;
  public categoryMap: object = {};
  public categoryMapFlipped: object = {};
  public youtubeSearchTerm = new Subject<string>();
  public observerAuthentication = new Subject<any>();

  public currentLanguageCode: string;

  public isPasswordUser = false;

  constructor(
    // public afs: AngularFirestore,
    // private fns: AngularFireFunctions,
    // public afAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone,
    public toster: ToastrService,
    private cookieService: CookieService,
    private http: HttpClient,
    private translate: TranslateService,
    private modalService: NgbModal,
    private kvService: KvService,
    private r2Service: R2Service
  ) {
    this.setCurrentLanguage(false);
    /**
     * Important : Subscribe to firebase authentication
     * Firebase notify our app whenever it authozied an user: first time login or auto login from cookies
     */
    // this.afAuth.authState.subscribe((user) => {
    //   setTimeout(() => {
    //     this.checkToLoadLatestVersion();
    //   });
    //   this.authenticationCallback(user);
    //   // console.log(user);
    //   // console.log(user.credential.accessToken);
    //   // let testYoutube = this.search('cat').subscribe((result) => {
    //   //   console.log('YOUTUBE TEST: ', result);
    //   // })
    // });
  }

  async checkToLoadLatestVersion() {
    let variables = await this.kvService.read("variables", "global");
    if (variables.app_version != environment.version) {
      Swal.fire({
        title: this.translate.instant("New version is available"),
        text: this.translate.instant(
          "Do you want to reload the app to get the latest version?"
        ),
        type: "warning",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.translate.instant("No"),
        confirmButtonText: this.translate.instant("Yes"),
      }).then((result) => {
        if (result.value) {
          let scripts = document.getElementsByTagName("script");
          let links = document.getElementsByTagName("link");
          let files = [location.origin + "/index.html"];
          for (let si = 0; si < scripts.length; si++) {
            try {
              if (scripts[si].src.includes(location.host)) {
                files.push(scripts[si].src);
              }
            } catch (e) {
              console.log(e);
            }
          }
          for (let li = 0; li < links.length; li++) {
            try {
              if (links[li].href.includes(location.host)) {
                files.push(links[li].href);
              }
            } catch (e) {
              console.log(e);
            }
          }
          window["hard_reload_files"] = files.length;
          let cb = () => {
            window["hard_reload_files"]--;
            if (window["hard_reload_files"] == 0) {
              window.location.reload();
            }
          };
          for (let file of files) {
            this.hardReloadFile(file, cb);
          }
        }
      });
    } else {
      if (
        variables.message_html &&
        variables.message_id &&
        localStorage.getItem("message_id") != variables.message_id
      ) {
        Swal.fire({
          title: "",
          html: variables.message_html,
        });
        localStorage.setItem("message_id", variables.message_id);
      }
    }
  }

  hardReloadFile(filePath: string, cb) {
    var oAjax = new XMLHttpRequest();
    oAjax.open("post", filePath);
    oAjax.setRequestHeader("Pragma", "no-cache");
    oAjax.setRequestHeader("Expires", "-1");
    oAjax.setRequestHeader("Cache-Control", "no-cache");
    oAjax.send();
    oAjax.onreadystatechange = () => {
      if (oAjax.readyState === 4) {
        cb();
      }
    };
  }

  setCurrentLanguage(updateVideosInSelectedLang: boolean = true) {
    this.currentLanguageCode = localStorage.getItem("language") || "en";
  }

  ngOnInit(): void {}

  isValidPaidUser() {
    return (
      this.userData.expiredDate &&
      this.userData.expiredDate > parseInt(moment().format("X"))
    );
  }

  async validateUserAndDevice(fromAuthProcess = false) {
    if (this.isValidPaidUser()) {
      let deviceInfo = await this.kvService.read(
        this.userData.email,
        "devices"
      );
      let remoteDeviceIds = deviceInfo.hasOwnProperty("dl")
        ? deviceInfo.dl
        : [];
      let concurrentDevices: { i: number; l: number }[] =
        remoteDeviceIds.filter((device) => {
          // There is an interval 300 seconds to update last time check, here it checks if a device has last time check >= 360 seconds
          // any device that has last time check < 360 seconds are considered as concurrent devices.
          return device.l >= parseInt(moment().format("X")) - 360;
        });
      let localDeviceId = this.getDeviceId();
      let existsLocalDevice = concurrentDevices.find((d) => {
        return d.i == localDeviceId;
      });
      // Keep the latest device
      if (!existsLocalDevice) {
        concurrentDevices.push({
          i: localDeviceId,
          l: parseInt(moment().format("X")),
        });
      }

      if (existsLocalDevice && fromAuthProcess) {
        concurrentDevices = concurrentDevices.map((d) => {
          if (d.i == localDeviceId) {
            d.l = parseInt(moment().format("X"));
          }
          return d;
        });
      }
      // sort by last time check asc, last device at the top will be signed out if more than 1 device are concurrently signed in.
      concurrentDevices = concurrentDevices.sort((d1, d2) => {
        return d1.l - d2.l;
      });
      let shouldSignOut = false;
      if (concurrentDevices.length > 1) {
        for (let i = 0; i < concurrentDevices.length; i++) {
          if (
            concurrentDevices[i].i == localDeviceId &&
            i != concurrentDevices.length - 1
          ) {
            shouldSignOut = true;
          }
        }
      }
      if (shouldSignOut) {
        concurrentDevices = concurrentDevices.filter((d) => {
          return d.i != localDeviceId;
        });
        await this.kvService.write(
          this.userData.email,
          "devices",
          JSON.stringify(deviceInfo)
        );
        Swal.fire({
          title: this.translate.instant("Warning"),
          html: this.translate.instant(
            "You have signed in on another device. Please sign in again."
          ),
          timer: 10000,
          allowOutsideClick: false,
          showCancelButton: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            // this.SignOut();
          }
        });
        // this.toster.warning(this.translate.instant('You have signed in on another device. Please sign in again.'));
      } else {
        deviceInfo.dl = concurrentDevices;
        await this.kvService.write(
          this.userData.email,
          "devices",
          JSON.stringify(deviceInfo)
        );
      }

      // if (shouldSignOut) {
      // this.SignOut();
      // Latest sign in will be kept
      // if (fromAuthProcess) {
      //   deviceRef.set(deviceInfo);
      // } else {
      //   // Other devices will be sign out
      //   this.SignOut();
      // }
      // }

      // Expiry check
      if (deviceInfo.e < parseInt(moment().format("X"))) {
        // // if not default user
        // if (!isEqual(this.schoolData.data, this.defaultFreeSchoolData.data)) {
        //   location.href = location.href;
        // }
      }
    }
  }

  getDeviceId(): number {
    let deviceId = localStorage.getItem("device_id");
    if (!deviceId || deviceId == "undefined") {
      deviceId = moment().format("X");
      localStorage.setItem("device_id", deviceId);
    }
    return parseInt(deviceId);
  }

  mobileAndTabletcheck() {
    let isMobile = false; //initiate as false
    // device detection
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        navigator.userAgent
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        navigator.userAgent.substr(0, 4)
      )
    ) {
      isMobile = true;
    }
    return isMobile;
  }
  // TODO: migrate to cloudflare worker and use paypal

  updateTranslations() {
    // this.fns.functions.useFunctionsEmulator(
    //   'http://localhost:5001/speaknplay/us-central1/updateTranslations'
    // );
    // const updateTranslations = this.fns.httpsCallable('updateTranslations');
    // return updateTranslations({
    //   uid: this.userData.uid,
    // })
    //   .toPromise()
    //   .then((response) => {
    //     console.log(response);
    //   });
  }

  // TODO: migrate to cloudflare queue
  createQueueJob(type, data, cb) {
    // this.fns.functions.useFunctionsEmulator('http://localhost:5001/speaknplay/us-central1/createQueueJob');
    // if (
    //   type == QueueType.UpdateTran &&
    //   location.hostname.includes('localhost')
    // ) {
    //   // this.updateTranslations();
    // } else {
    //   const createQueueJob = this.fns.httpsCallable('createQueueJob');
    //   return createQueueJob({
    //     production: environment.production,
    //     uid: this.userData.uid,
    //     type: type,
    //     data: data,
    //   })
    //     .toPromise()
    //     .then((response) => {
    //       console.log(response);
    //       cb();
    //     });
    // }
    // var ref = firebase.database().ref('queue/tasks');
    // data['uid'] = this.user.uid;
    // data['type'] = type;
    // ref.push(data).then(() => {
    //   cb();
    // });
  }

  setTranslationLanguage(lang: string, isDefault: boolean = false) {
    // trigger change detection in the next cycle to prevent ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      let availableLanguages = ["en", "es", "fr", "pt", "vi"];
      // i18n not ready, set application display in English
      if (availableLanguages.includes(lang)) {
        if (isDefault) {
          this.translate.setDefaultLang(lang);
        }
        this.translate.use(lang);
      } else {
        console.log("Not available translation: " + lang);
        this.translate.use("en");
        if (isDefault) {
          this.translate.setDefaultLang("en");
        }
      }
    });
  }

  reloadPage(target = null) {
    const goToUrl = target ? target : this.router.url;
    // this.router.navigateByUrl('reload', { skipLocationChange: true }).then(() => {
    //   this.router.navigate([goToUrl]);
    // });
    location.href = location.origin + goToUrl;
  }

  async asyncUploadDataURL(base64: string, folder, extension): Promise<string> {
    return new Promise(async (resolve, reject) => {
      // const storageRef = firebase.storage().ref();
      const path = this.userData.uid + "_" + Date.now() + "." + extension;
      await this.r2Service.uploadFile(base64, path, 1);
      return resolve(path);
    });
  }

  toastSubmit() {
    let progressionToast = this.toster.warning(
      this.translate.instant("Submitting..."),
      "",
      {
        extendedTimeOut: 100000000,
        timeOut: 100000000, // The progress bar is displayed only if there is a timeout, so I set a very large timeout to close it manually
        enableHtml: true,
        tapToDismiss: false,
        progressBar: true,
        progressAnimation: "increasing",
      }
    );
    return progressionToast;
  }
  toastSaved(saveButtonRef?: { label: string }) {
    if (saveButtonRef) {
      saveButtonRef.label = "Saved";
    }

    this.toster.clear();
    this.toster.success(this.translate.instant("Done"), "", {
      extendedTimeOut: 3000,
      timeOut: 5000,
      enableHtml: true,
      tapToDismiss: false,
    });
    setTimeout(() => {
      if (saveButtonRef) {
        saveButtonRef.label = "Save";
      }
    }, 5000);
  }

  swagConfirm(
    title,
    text,
    confirmButtonText,
    cancelButtonText,
    confirmedCallback,
    cancelCallback
  ) {
    Swal.fire({
      title: title ? this.translate.instant(title) : null,
      text: text ? this.translate.instant(text) : null,
      type: "warning",
      showCancelButton: true,
      // confirmButtonColor: '#3085d6',
      // cancelButtonColor: '#d33',
      confirmButtonText: confirmButtonText
        ? this.translate.instant(confirmButtonText)
        : null,
      cancelButtonText: cancelButtonText
        ? this.translate.instant(cancelButtonText)
        : null,
    }).then((result) => {
      if (result.value) {
        confirmedCallback();
      } else {
        cancelCallback();
      }
    });
  }
}
