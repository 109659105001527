import { BrowserModule } from "@angular/platform-browser";
import {
  NgModule,
  Injectable,
  ErrorHandler,
  APP_INITIALIZER,
} from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { ToastrModule } from "ngx-toastr";

import { AuthService } from "./services/auth.service";
import { AdminGuard } from "./guard/admin.guard";
import { CookieService } from "ngx-cookie-service";
import { environment } from "../environments/environment";
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
// Directives
import { LanguagesComponent } from "./components/languages/languages.component";
import { NumberSuffixPipe } from "./pipe/number-suffix.pipe";
import { HomeComponent } from "./components/home/home.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { HttpClientModule } from "@angular/common/http";

import { PurchaseComponent } from "./components/purchase/purchase.component";
import { PrivacyPolicyComponent } from "./components/privacy-policy/privacy-policy.component";

import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";
import { ReloadComponent } from "./components/reload/reload.component";
import { SuccessPurchaseComponent } from "./components/success-purchase/success-purchase.component";
import { CancelPurchaseComponent } from "./components/cancel-purchase/cancel-purchase.component";
declare var require;
if (environment.production) {
  Sentry.init({
    dsn: "https://7e0840c9563e473a8e1696895693c957@o952196.ingest.sentry.io/5901537",
    release: require("../../package.json").version,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ["localhost:4200", /^\//],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new Sentry.Replay(),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

@NgModule({
  declarations: [
    AppComponent,
    LanguagesComponent,
    NumberSuffixPipe,
    HomeComponent,
    PurchaseComponent,
    PrivacyPolicyComponent,
    ReloadComponent,
    SuccessPurchaseComponent,
    CancelPurchaseComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: "en",
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    CommonModule,
    RouterModule,
    NgbModule,
    NgSelectModule,

    HttpClientModule,
  ],
  exports: [TranslateModule, LanguagesComponent, NumberSuffixPipe],
  providers: [
    AuthService,
    AdminGuard,
    CookieService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
