import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "src/app/services/auth.service";
import { Component, OnInit } from "@angular/core";
import * as moment from "moment";
import { environment } from "src/environments/environment";
import { APIResponse, User } from "./services/constant.service";
import { HttpClient } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
declare var require;
const Swal = require("sweetalert2");
declare var gapi: any; // Add this line to use the gapi variable

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "Speak to play video";
  public isConnected = false;
  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private http: HttpClient,
    private toster: ToastrService
  ) {
    let lang = localStorage.getItem("language");
    lang = !lang ? navigator.language : lang;
    lang = lang.split("-")[0];
    this.authService.setTranslationLanguage(lang, true);
  }

  ngOnInit() {}
}
