import { ReloadComponent } from "./components/reload/reload.component";
import { PrivacyPolicyComponent } from "./components/privacy-policy/privacy-policy.component";
import { PurchaseComponent } from "./components/purchase/purchase.component";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./components/home/home.component";
import { SuccessPurchaseComponent } from "./components/success-purchase/success-purchase.component";
import { CancelPurchaseComponent } from "./components/cancel-purchase/cancel-purchase.component";

const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
  },
  {
    path: "privacy-policy",
    component: PrivacyPolicyComponent,
  },
  {
    path: "purchase",
    component: PurchaseComponent,
  },
  {
    path: "success-purchase/:ref",
    component: SuccessPurchaseComponent,
  },
  {
    path: "cancel-purchase",
    component: CancelPurchaseComponent,
  },
  {
    path: "reload",
    component: ReloadComponent,
  },
  {
    path: "**",
    redirectTo: "",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // preloadingStrategy: PreloadAllModules,
      anchorScrolling: "enabled",
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
